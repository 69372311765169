import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	InputBase,
	List,
	ListItem,
	ListItemText,
	Typography,
} from "@mui/material";
import React from "react";
import {
	useCreateSubcategory,
	useGetSubCategoryFromCatID,
	useUpdateSubcategory,
} from "../../query/category";
import { MdClose } from "react-icons/md";
import tableOptionsStyle from "../../style/tableOptions";
import { useForm } from "react-hook-form";
import { responseHandler } from "../../utilities/response-handler";
import snackContext from "../../context/snackProvider";
import { FiEdit, FiEdit2 } from "react-icons/fi";

const SubcategoryDialog = ({ open, onClose, category }) => {
	const snack = React.useContext(snackContext);
	const { data } = useGetSubCategoryFromCatID(category?.id);
	// console.log(category, data);
	const { register, handleSubmit, reset } = useForm({});

	const { mutateAsync: createSubcategory } = useCreateSubcategory();

	const onCreate = async (data) => {
		const res = await responseHandler(() =>
			createSubcategory({ ...data, category_id: category?.id })
		);
		console.log(res);
		if (res.status) {
			snack.createSnack(res.msg);
			reset();
		} else {
			snack.createSnack(res.msg, "error");
		}
	};
	return (
		<>
			<Dialog
				open={open}
				onClose={onClose}
				PaperProps={{
					sx: {
						width: "95vw",
					},
				}}
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<Typography
						variant={"button"}
						sx={{ mr: "auto" }}
					>
						Subcategories
					</Typography>
					<IconButton
						onClick={onClose}
						size={"small"}
					>
						<MdClose />
					</IconButton>
				</DialogTitle>
				<Divider />
				<DialogContent
					sx={{
						p: 1,
					}}
				>
					{data?.data?.value?.map?.((sub) => (
						<SubcategoryItem
							key={sub.id}
							sub={sub}
						/>
					))}
				</DialogContent>
				<Divider />
				<form onSubmit={handleSubmit(onCreate)}>
					<DialogActions>
						<InputBase
							sx={{ ...tableOptionsStyle, width: "100%" }}
							placeholder={"New subcategory name"}
							{...register("title_en")}
							endAdornment={
								<Button
									variant={"contained"}
									size={"small"}
									color={"black"}
									type={"submit"}
								>
									Create
								</Button>
							}
						/>
					</DialogActions>
				</form>
			</Dialog>
		</>
	);
};

const SubcategoryItem = ({ sub }) => {
	const snack = React.useContext(snackContext);
	const [edit, setEdit] = React.useState(false);

	const { register, handleSubmit, reset } = useForm({
		defaultValues: {
			title_en: sub.title_en,
		},
	});
	const { mutateAsync: updatesubcategory } = useUpdateSubcategory();

	const onUpdate = async (data) => {
		const res = await responseHandler(() =>
			updatesubcategory({ ...data, sub_category_id: sub?.id })
		);
		if (res.status) {
			snack.createSnack(res.msg);
			setEdit(false);
		} else {
			snack.createSnack(res.msg, "error");
		}
	};

	return edit ? (
		<form onSubmit={handleSubmit(onUpdate)}>
			<ListItem sx={{ px: 1.2 }}>
				<InputBase
					sx={{ ...tableOptionsStyle, width: "100%" }}
					placeholder={"Update subcategory name"}
					{...register("title_en")}
					endAdornment={
						<>
							<IconButton
								size={"small"}
								sx={{ mr: 1 }}
								onClick={() => setEdit(false)}
							>
								<MdClose />
							</IconButton>
							<Button
								variant={"contained"}
								size={"small"}
								color={"black"}
								type={"submit"}
							>
								Update
							</Button>
						</>
					}
				/>
			</ListItem>
		</form>
	) : (
		<ListItem>
			<ListItemText primary={sub.title_en} />
			<IconButton
				size={"small"}
				onClick={() => setEdit(true)}
			>
				<FiEdit2 />
			</IconButton>
		</ListItem>
	);
};

export default SubcategoryDialog;
