import { useMutation, useQuery, useQueryClient } from "react-query";
import instance from "../service/instance";

const getAllCategory = () => {
	return instance.get(`category/get-all-category-info`);
};

export const useGetAllCategory = () => {
	return useQuery(["get-all-category"], () => getAllCategory(), {
		// refetchInterval: 20000,
	});
};

const getSubCategoryFromCatID = (category_id) => {
	return instance.get(
		`subcategory/get-subcategory-info-by-category/${category_id}`
	);
};

export const useGetSubCategoryFromCatID = (category_id) => {
	return useQuery(
		["get-sub-category-cat", category_id],
		() => getSubCategoryFromCatID(category_id),
		{
			// refetchInterval: 20000,
			enabled: !!category_id,
			retry: 0,
		}
	);
};

const createCategory = (data) => {
	return instance.postForm(`category/create`, data);
};

export const useCreateCategory = () => {
	const queryClient = useQueryClient();
	return useMutation(createCategory, {
		onSuccess: () => queryClient.invalidateQueries("get-all-category"),
	});
};

const createSubcategory = (data) => {
	return instance.postForm(`subcategory/create`, data);
};

export const useCreateSubcategory = () => {
	const queryClient = useQueryClient();
	return useMutation(createSubcategory, {
		onSuccess: () => queryClient.invalidateQueries("get-sub-category-cat"),
	});
};

const updateCategory = (data) => {
	return instance.postForm(`category/update`, data);
};

export const useUpdateCategory = () => {
	const queryClient = useQueryClient();
	return useMutation(updateCategory, {
		onSuccess: () => queryClient.invalidateQueries("get-all-category"),
	});
};

const updateSubcategory = (data) => {
	return instance.postForm(`subcategory/update`, data);
};

export const useUpdateSubcategory = () => {
	const queryClient = useQueryClient();
	return useMutation(updateSubcategory, {
		onSuccess: () => queryClient.invalidateQueries("get-sub-category-cat"),
	});
};

const deleteCategory = (id) => {
	return instance.delete(`category/delete/${id}`);
};

export const useDeleteCategory = () => {
	const queryClient = useQueryClient();
	return useMutation(deleteCategory, {
		onSuccess: () => queryClient.invalidateQueries("get-all-category"),
	});
};
