import { useMutation, useQuery, useQueryClient } from "react-query";
import instance from "../service/instance";

const createOffer = (data) => {
    return instance.postForm(`offer/create`, data);
  };
  
  export const useCreateOffer = () => {
    const queryClient = useQueryClient();
    return useMutation(createOffer, {
      onSuccess: () => queryClient.invalidateQueries("get-all-product"),
    });
  };