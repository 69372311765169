import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import Header from "../components/Header";
import { Container } from "@mui/material";
import { authContext } from "../context/AuthProvider";

// pages
import Order from "../pages/Order";
import Product from "../pages/Product";
import Category from "../pages/Category";
import User from "../pages/User";
import BIOrder from "../pages/BIOrder";
import PaymentOrder from "../pages/PaymentOrder";
import UserOrder from "../pages/User/UserOrder";
import Wallet from "../pages/User/Wallet";
import Image from "../pages/Image";
import News from "../pages/AdditionalInfo/News";
import AdditionalInfo from "../pages/AdditionalInfo";
import Offer from "../pages/Offer/Offer";
import CustomerDetails from "../pages/CustomerDetails/CustomerDetails";

const AppRoute = () => {
  const authCntxt = React.useContext(authContext);

  return (
    <>
      {authCntxt.token ? (
        <>
          <Header />
          <Container>
            <Routes path="/">
              <Route index element={<Dashboard />} />
              {/* <Route path="/login" element={<>Order Lists</>} /> */}
              <Route path="order-list" element={<Order />} />
              <Route path="bi-order-list" element={<BIOrder />} />
              <Route path="payment-order" element={<PaymentOrder />} />
              <Route path="customer-details" element={<CustomerDetails />} />
              <Route path="product-list" element={<Product />} />
              <Route path="product-offer" element={<Offer />} />
              <Route path="user/:uid" element={<Navigate to={"profile"} />} />
              <Route path="user/:uid/:path_url" element={<User />} />
              <Route path="images" element={<Image />} />
              <Route path="settings" element={<>Settings</>} />
              <Route path="create-order" element={<>Create Order</>} />
              <Route path="create-bim-order" element={<>Create BIM Order</>} />
              <Route path="category" element={<Category />} />
              <Route
                path="additional-info"
                element={<Navigate to={"news"} />}
              />
              <Route
                path="additional-info/:path_url"
                element={<AdditionalInfo />}
              />
            </Routes>
          </Container>
        </>
      ) : (
        <>
          <Login />
        </>
      )}
    </>
  );
};

export default AppRoute;
