import React from 'react';
import { TextField, Box, Button } from '@mui/material';
import { parseISO, format } from 'date-fns';

const CustomDateRangePicker = ({ onDateRangeSelect }) => {
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');

  const handleApply = () => {
    if (startDate && endDate) {
      onDateRangeSelect({
        startDate: parseISO(startDate),
        endDate: parseISO(endDate),
      });
      console.log(startDate);
    }
  };

  return (
    <Box sx={{ padding: 2, display: "flex" }}>
      <TextField
        label="Start Date"
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        sx={{ marginRight: 2 }}
      />
      <TextField
        label="End Date"
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        InputLabelProps={{ shrink: true }}
        sx={{ marginRight: 2 }}
      />
      <Button variant="contained" onClick={handleApply}>
        Apply
      </Button>
    </Box>
  );
};

export default CustomDateRangePicker;
