import { useQuery } from "react-query";
import instance from "../service/instance";

const getInvoice = (id) => {
  return instance.get(`order/generate-invoice/${id}`);
};

export const useGetInvoice = (id) => {
  return useQuery(["get-invoice", id], () => getInvoice(id), {
    enabled: !!id,
  });
};

const getBiInvoice = (id) => {
  return instance.get(`order/bi/generate-invoice/${id}`);
};

export const useGetBiInvoice = (id) => {
  return useQuery(["get-bi-invoice", id], () => getBiInvoice(id), {
    enabled: !!id,
  });
};
