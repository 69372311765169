import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputBase,
  ListItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import ButtonSwitch from "../../components/ButtonSwitch";
import snackContext from "../../context/snackProvider";
import {
  useCreateNews,
  useDelTicker,
  useGetNewsTicker,
  useUpdateNews,
} from "../../query/news";
import { responseHandler } from "../../utilities/response-handler";
import { GrDocumentUpdate } from "react-icons/gr";
import { IoMdCreate } from "react-icons/io";
import { Container } from "@mui/system";
import tableOptionsStyle from "../../style/tableOptions";
import { MdAdd, MdOutlineDeleteOutline } from "react-icons/md";
import { useForm } from "react-hook-form";

const News = () => {
  const { data: newsTicker, isLoading, isError } = useGetNewsTicker();
  const [news, setNews] = React.useState([]);

  React.useEffect(() => {
    if (isError) return;
    setNews(newsTicker?.data || []);
  }, [isLoading, newsTicker]);
  // console.log(news);
  return (
    <>
      <Box>
        <CreateNews />
      </Box>
      <Box border={"dashed"} borderColor={"#D3D3D3"} sx={{ py: 1, my: 1 }}>
        {isLoading && (
          <Stack alignItems="center" py={2} pb={4}>
            <CircularProgress />
          </Stack>
        )}
        {news?.map?.((newsItem, index) => (
          <React.Fragment key={index}>
            <NewsBox newsItem={newsItem} />
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

const NewsBox = ({ newsItem }) => {
  const snack = React.useContext(snackContext);
  const [newsText, setNewsText] = React.useState(newsItem?.news);

  const { mutateAsync: mutateDelTicker, isLoading: delLoading } =
    useDelTicker();

  const handleDel = async (id) => {
    const res = await responseHandler(() => mutateDelTicker(id));
    if (res.status) {
      snack.createSnack(res.msg);
    } else {
      snack.createSnack(res.msg, "error");
    }
  };

  const { mutateAsync: updateNews } = useUpdateNews();

  const updateNewsState = async (id, data) => {
    const res = await responseHandler(() => updateNews({ id, data }));
    if (res.status) snack.createSnack(res.msg);
    else snack.createSnack(res.msg, "error");
  };

  return (
    <>
      <Container sx={{ my: 1 }}>
        <Paper
          elevation={0}
          sx={{
            p: 1,
            pb: 0.5,
            mb: 1,
            "&:last-child": { mb: 0 },
            border: "1px solid #ddd",
          }}
        >
          <Stack
            direction="row"
            alignItems={"flex-end"}
            justifyContent={"space-between"}
            columnGap={4}
          >
            <Stack direction="column" rowGap={0.5} flex={1}>
              <InputBase
                value={newsText}
                multiline
                fullWidth
                onChange={(e) => {
                  setNewsText(e.target.value);
                }}
              />

              <Typography variant={"caption"}>
                {moment(newsItem?.createdAt).format("lll")}
              </Typography>
            </Stack>
            <Stack direction={"row"}>
              <ButtonSwitch
                checked={newsItem?.is_active}
                color={"success"}
                onClick={() => {
                  updateNewsState(newsItem?.id, {
                    is_active: newsItem?.is_active ? 0 : 1,
                  });
                }}
              />
              <IconButton
                size="small"
                onClick={() => {
                  handleDel(newsItem?.id, {
                    news: newsText,
                  });
                }}
                color={"error"}
              >
                <MdOutlineDeleteOutline />
              </IconButton>

              {newsText !== newsItem.news && (
                <IconButton
                  size="small"
                  onClick={() => {
                    updateNewsState(newsItem?.id, {
                      news: newsText,
                    });
                  }}
                >
                  <GrDocumentUpdate />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Paper>
      </Container>
    </>
  );
};

const CreateNews = () => {
  const snack = React.useContext(snackContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutateAsync: mutateCreateNews, isLoading } = useCreateNews();

  const handleCreate = async (data) => {
    const res = await responseHandler(() =>
      mutateCreateNews({ ...data, is_active: 1 })
    );
    if (res.status) {
      snack.createSnack(res.msg);
    } else {
      snack.createSnack(res.msg, "error");
    }
  };
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          border: "1px solid #ccc",
          my: 2,
        }}
      >
        <form onSubmit={handleSubmit(handleCreate)}>
          <Grid
            container
            rowGap={1}
            alignItems={"end"}
            justifyContent={"flex-end"}
          >
            <Grid xs={12}>
              <ListItem>
                <InputBase
                  sx={{
                    bgcolor: "#f3f3f3",
                    borderRadius: "4px",
                    p: 1,
                    mt: 1,
                  }}
                  placeholder={"Enter the news"}
                  {...register("news", {
                    required: true,
                  })}
                  fullWidth
                  multiline
                  rows={3}
                />
              </ListItem>
            </Grid>
            <Grid item>
              <Button
                variant={"contained"}
                size={"small"}
                sx={{ mb: 1, mx: 2 }}
                type={"submit"}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default News;
