import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useGetInvoice } from "../../query/inovoice";
import OrderDialog from "./OrderDialog";

const orderContext = React.createContext();

export const OrderProvider = ({ children }) => {
  // Get Search Params
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Parameter State
  const [orderInfo, setOrderInfo] = React.useState();

  // Fetch Data
  const {
    data: orderData,
    isLoading,
    isError,
    isRefetching,
  } = useGetInvoice(searchParams.get("order"));

  // Set Order to State
  React.useEffect(() => {
    if (isLoading || isError) return;
    if (!orderData?.data.status) return;
    setOrderInfo(orderData.data.value);
    // console.log(orderData.data.value);
  }, [isLoading, isRefetching]);

  return (
    <orderContext.Provider
      value={{
        info: orderInfo,
        isLoading: isLoading,
        showOrder: (orderId) => {
          // console.log(orderId);
          navigate({
            search: `?order=${orderId}`,
          });
        },
      }}
    >
      {children}
      <>
        <Backdrop
          open={isLoading}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          onClick={() => {
            navigate({
              search: ``,
            });
            setOrderInfo();
          }}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
        <OrderDialog
          open={!!orderInfo}
          onClose={() => {
            navigate({
              search: ``,
            });
            setOrderInfo();
          }}
        />
      </>
    </orderContext.Provider>
  );
};

export default orderContext;
