import {
  Alert,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { MdClose, MdOutlinePrint } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import { Preview, print } from "react-html2pdf";
//   import invoiceContext from ".";

import BIlogo from "../../assets/bim-order.svg";

const BiMultiInvoice = ({ open, onClose, data }) => {
  const printRef = React.useRef(null);
  // const invoice = React.useContext(invoiceContext);
  const [comment, setComment] = React.useState(true);
  const toggleComment = () => setComment(!comment);

  const reactToPrintContent = React.useCallback(() => {
    return printRef.current;
  }, [printRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    //   documentTitle: invoice.info?.id
    //     ? "invoice-" + invoice.info?.id
    //     : "invoice-" + Date.now(),

    removeAfterPrint: true,
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          position: "absolute",
        }}
        PaperProps={{
          sx: {
            minWidth: {
              xs: "95vw",
              md: "800px",
            },
            maxWidth: { xs: "95vw", md: "1200px" },
            maxHeight: {
              xs: "97vh",
            },
            minHeight: {
              xs: "97vh",
              sm: "unset",
            },
          },
        }}
      >
        <DialogTitle>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            columnGap={1}
          >
            <ButtonGroup>
              <Button
                color={"info"}
                variant={"contained"}
                size={"small"}
                startIcon={<MdOutlinePrint />}
                onClick={handlePrint}
              >
                Print
              </Button>
              <Button
                color={"black"}
                variant={"contained"}
                size={"small"}
                // startIcon={<MdOutlinePrint />}
                onClick={
                  () => print()
                  //   invoice.info?.id
                  //     ? "invoice-" + invoice.info?.id
                  //     : "invoice-" + Date.now(),
                  //   "jsx-template"
                }
              >
                Download
              </Button>
              <Button
                color={"primary"}
                variant={comment ? "contained" : "outlined"}
                size={"small"}
                // startIcon={<MdOutlinePrint />}
                onClick={() => toggleComment()}
              >
                Comment
              </Button>
            </ButtonGroup>
            <IconButton onClick={onClose} size={"small"} color={"error"}>
              <MdClose />
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider />
        <Preview id={"jsx-template"}>
          <Box>
            <DialogContent ref={printRef}>
              {data?.map((data, index) => (
                <div style={{ pageBreakAfter: "always" }} key={index}>
                  <PrintableArea data={data} />
                </div>
              ))}
            </DialogContent>
          </Box>
        </Preview>
      </Dialog>
    </>
  );
};

const PrintableArea = ({ data }) => {
  return (
    <>
      <Grid
        container
        sx={{
          mt: 0.5,
          "*": {
            fontSize: "0.85em",
          },
        }}
      >
        <Grid item xs={8}>
          <Avatar
            src={"/bi-extended.svg"}
            variant={"square"}
            sx={{
              height: "auto",
              width: "230px",
              // mx: "auto",
              background: "transparent",
              borderColor: "none",
            }}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            textAlign: "right",
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Brothers Importing Inc</Typography>
          <Typography variant={"subtitle2"}>
            House 91/i, Road: 7/A, Dhanmondi,Dhaka 1209
          </Typography>
          <Typography variant={"subtitle2"}>+8801830093682</Typography>
          <Typography variant={"subtitle2"}>brothersimporting@gmail.com</Typography>
        </Grid>
      </Grid>

      <Typography variant="caption">
        <b>Invoice No: {data?.id}</b>
      </Typography>

      <Paper
        sx={{
          mt: 0.5,
          mb: 1,
          bgcolor: "#E9EEEE",
          px: 2,
          py: 1,
          "*": {
            fontSize: "0.9em",
          },
        }}
        elevation={0}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant="button"
              sx={{
                fontWeight: "bold",
              }}
            >
              bill to
            </Typography>
            <Box
              sx={{
                // mt: 1,
              }}
            />
            <Typography variant={"subtitle2"} sx={{ fontWeight: "bold" }}>
              {data?.receiver_name}
            </Typography>
            <Typography variant={"subtitle2"}>
              {data?.receiver_address}
            </Typography>
            <Typography variant={"subtitle2"}>
              {data?.receiver_number}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              textAlign: "right",
            }}
          >
            <Typography variant={"subtitle2"}>
              <b>Invoice Date:</b> {moment().format("ll")}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <TableContainer>
        <Table
          sx={{
            "& tr:last-child th": {
              border: "none",
            },
            "*": {
              fontSize: "0.85em",
            },
          }}
        >
          <TableHead
            sx={{
              bgcolor: "#E9EEEE",
            }}
          >
            <TableRow>
              <TableCell
                padding={"none"}
                sx={{
                  fontWeight: "bold",
                  p: 1,
                  px: 2,
                }}
              >
                Item Description
              </TableCell>
              <TableCell
                align="center"
                padding={"none"}
                sx={{
                  fontWeight: "bold",
                  p: 1,
                  px: 2,
                }}
              >
                Quantity
              </TableCell>
              <TableCell
                align="center"
                padding={"none"}
                sx={{
                  fontWeight: "bold",
                  p: 1,
                  px: 2,
                }}
              >
                Price
              </TableCell>
              <TableCell
                align="right"
                padding={"none"}
                sx={{
                  fontWeight: "bold",
                  p: 1,
                  px: 2,
                }}
              >
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{}}>
            {data?.carts?.map((cart) => {
              let colors = JSON.parse(cart.cart_info)?.color;
              return (
                <TableRow key={cart.id}>
                  <TableCell
                    padding={"none"}
                    sx={{
                      width: "60%",
                      p: 1,
                      px: 2,
                      fontSize: "1em",
                    }}
                  >
                    <b>{cart.product.title_en}</b>
                    {/* <br /> */}
                    <Typography
                      sx={{
                        ml: 1,
                      }}
                      variant={"caption"}
                    >
                      {Object.keys(colors)?.map(
                        (color, index) =>
                          `${index ? ", " : ""}${color} - ${colors[color]}Pcs`
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    padding={"none"}
                    sx={{
                      minWidth: "fit-content",
                      p: 1,
                      px: 2,
                    }}
                  >
                    {cart.quantity}
                  </TableCell>
                  <TableCell
                    align="center"
                    padding={"none"}
                    sx={{
                      minWidth: "fit-content",
                      p: 1,
                      px: 2,
                    }}
                  >
                    {cart.price}৳
                  </TableCell>
                  <TableCell
                    align="right"
                    padding={"none"}
                    sx={{
                      minWidth: "fit-content",
                      p: 1,
                      px: 2,
                    }}
                  >
                    {cart.total_amount}৳
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {!data?.carts?.length && (
        <Alert
          severity="error"
          sx={{
            mt: 1,
            width: "100%",
          }}
        >
          No Carts Found!!
        </Alert>
      )}

      <Grid
        container
        sx={{
          mt: 0.5,
          "*": {
            fontSize: "0.8em",
          },
        }}
      >
        <Grid item xs={8}>
          {/* {showComment && (
              <Box
                sx={{
                  maxWidth: "300px",
                }}
              >
                 
                <Typography variant={"button"}>Comment:</Typography>
                
                <Typography variant={"caption"}>
                  {Object.keys(
                    data.info?.other_details
                      ? JSON.parse(data.info?.other_details)
                      : {}
                  ).includes("comment")
                    ? JSON.parse(data.info?.other_details).comment
                    : ""}
                </Typography>
              </Box>
            )} */}
        </Grid>
        <Grid item xs={3}>
          <Typography variant={"button"}>Subtotal</Typography>
          <br />
          <Typography variant={"button"}>Delivery Fee</Typography>
          <br />
          <Typography variant={"button"}>Cod Charge</Typography>
          <br />
          <Typography variant={"button"} sx={{ fontWeight: "bold", fontSize: 13 }}>
            Total
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{
            textAlign: "right",
          }}
        >
          <Typography variant={"button"}>{data?.sub_total}৳</Typography>
          <br />
          <Typography variant={"button"}>
            {data?.shipping_total_cost}৳
          </Typography>
          <br />
          <Typography variant={"button"}>
            {data?.cod_charge}৳
          </Typography>
          <br />
          <Typography variant={"button"} sx={{ fontWeight: "bold", fontSize: 13 }}>
            {data?.total_amount}৳
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default BiMultiInvoice;
