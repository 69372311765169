import React from "react";

import {
	Chip,
	Container,
	Grid,
	IconButton,
	InputBase,
	MenuItem,
	Paper,
	Select,
	Button,
	Avatar,
} from "@mui/material";

import DataTable from "../../components/DataTable";
import { useDeleteCategory, useGetAllCategory } from "../../query/category";
import { rootURL } from "../../service/instance";
import ButtonSwitch from "../../components/ButtonSwitch";
import tableOptionsStyle from "../../style/tableOptions";

import { RiDeleteBin5Fill } from "react-icons/ri";
import { IoMdEye } from "react-icons/io";
import { FiEdit2 } from "react-icons/fi";
import { MdAdd } from "react-icons/md";
import CreateCategory from "./CreateCategory";
import { responseHandler } from "../../utilities/response-handler";
import snackContext from "../../context/snackProvider";
import UpdateCategory from "./UpdateCategory";
import SubcategoryDialog from "./SubcategoryDialog";

const Index = () => {
	const snack = React.useContext(snackContext);
	const { data, isLoading } = useGetAllCategory();
	const [open, setOpen] = React.useState(false);
	const onClose = () => setOpen(!open);

	const [info, setInfo] = React.useState();
	const onCloseUpdate = () => setInfo();

	const [selected, setSelected] = React.useState();

	const { mutateAsync: deleteAsync, isLoading: deleteLoading } =
		useDeleteCategory();

	const deleteCategory = async (data) => {
		const res = await responseHandler(() => deleteAsync(data));
		if (res.status) {
			snack.createSnack(res.msg);
		} else {
			snack.createSnack(res.msg, "error");
		}
	};

	const cols = [
		// {
		//   headerName: "#",
		//   headerAlign: "center",
		//   field: "show_info",
		//   width: 60,
		//   align: "center",
		//   renderCell: () => (
		//     <>
		//       <IconButton>
		//         <IoMdEye />
		//       </IconButton>
		//     </>
		//   ),
		// },
		{
			headerName: "",
			headerAlign: "center",
			field: "receiver_number",
			align: "center",
			width: 80,
			renderCell: (params) => (
				<Avatar
					src={rootURL + params.row.photo}
					variant="square"
				/>
			),
		},

		{
			headerName: "Category Name",
			//   headerAlign: "center",
			field: "title_en",
			minWidth: 120,
			flex: 1,
		},
		{
			headerName: "Subcategory",
			headerAlign: "center",
			field: "sub-category",
			Width: 120,
			// flex: 1,
			align: "center",
			renderCell: (d) => (
				<>
					<IconButton onClick={() => setSelected(d.row)}>
						<IoMdEye />
					</IconButton>
				</>
			),
		},

		{
			headerName: "Status",
			headerAlign: "center",
			field: "status",
			align: "center",
			renderCell: (params) => (
				<ButtonSwitch
					checked={params.row.status === "active"}
					color={"success"}
				/>
			),
		},
		{
			headerName: "Action",
			headerAlign: "center",
			field: "actions",
			align: "center",
			width: 120,
			renderCell: (d) => (
				<>
					<IconButton
						size={"small"}
						sx={{
							mr: 1,
						}}
						disabled={deleteLoading}
						onClick={() => deleteCategory(d.row.id)}
					>
						<RiDeleteBin5Fill />
					</IconButton>
					<IconButton
						size={"small"}
						disabled={deleteLoading}
						onClick={() => setInfo(d.row)}
					>
						<FiEdit2 />
					</IconButton>
				</>
			),
		},
	];
	return (
		<>
			<Container
				sx={{
					py: 2,
				}}
			>
				<Paper
					elevation={0}
					sx={{
						p: 2,
						border: "1px solid #ccc",
						my: 2,
					}}
				>
					<Grid
						container
						rowGap={1}
						columnGap={1}
						alignItems={"center"}
						justifyContent={"space-between"}
					>
						<Grid
							item
							xs={12}
							md={8.8}
						>
							<InputBase
								placeholder="Search Category"
								sx={tableOptionsStyle}
								onChange={(e) => {
									//   setParams({
									//     ...params,
									//     filters: [`title_en~${e.target.value}`],
									//   });
								}}
								fullWidth
							/>
						</Grid>
						<Grid
							item
							xs={12}
							md={3}
						>
							<Button
								variant={"contained"}
								color={"primary"}
								size={"large"}
								sx={{
									height: "52px",
								}}
								startIcon={<MdAdd />}
								fullWidth
								onClick={onClose}
							>
								Add Category
							</Button>
						</Grid>
					</Grid>
				</Paper>{" "}
				<DataTable
					columns={cols}
					rows={data?.data?.value || []}
					isLoading={isLoading}
					width={"auto"}
				/>
				<CreateCategory
					open={open}
					onClose={onClose}
				/>
				{!!info && (
					<UpdateCategory
						open={!!info}
						onClose={onCloseUpdate}
						info={info}
					/>
				)}
				{!!selected && (
					<SubcategoryDialog
						open={!!selected}
						onClose={() => setSelected()}
						category={selected}
					/>
				)}
			</Container>
		</>
	);
};

export default Index;
