import { Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import DropZone from "../../components/DropZone";
import { useGetImage, useUpdateImage } from "../../query/images";
import { getAttachment } from "../../service/instance";

const Image = () => {
	const { data, isLoading } = useGetImage();

	const { mutateAsync } = useUpdateImage();
	const uploadImage = async (file, name) => {
		await mutateAsync({ file, name });
	};
	return (
		<>
			<Typography
				variant="h5"
				sx={{
					pt: 2,
					my: 2,
				}}
			>
				Banner
			</Typography>
			<Stack
				direction={"row"}
				columnGap={1}
				rowGap={1}
				flexWrap={"wrap"}
			>
				{data && (
					<>
						<DropZone
							defaultValue={{
								preview: getAttachment(data?.first_banner),
							}}
							onDelete={() => true}
							onChange={(file) => {
								uploadImage(file, "first_banner");
							}}
						/>
						<DropZone
							defaultValue={{
								preview: getAttachment(data?.second_banner),
							}}
							onDelete={() => true}
							onChange={(file) => {
								uploadImage(file, "second_banner");
							}}
						/>
						<DropZone
							defaultValue={{
								preview: getAttachment(data?.third_banner),
							}}
							onDelete={() => true}
							onChange={(file) => {
								uploadImage(file, "third_banner");
							}}
						/>
						<DropZone
							defaultValue={{
								preview: getAttachment(data?.first_card),
							}}
							onDelete={() => true}
							onChange={(file) => {
								uploadImage(file, "first_card");
							}}
						/>
						<DropZone
							defaultValue={{
								preview: getAttachment(data?.second_card),
							}}
							onDelete={() => true}
							onChange={(file) => {
								uploadImage(file, "second_card");
							}}
						/>
						<DropZone
							defaultValue={{
								preview: getAttachment(data?.third_card),
							}}
							onDelete={() => true}
							onChange={(file) => {
								uploadImage(file, "third_card");
							}}
						/>
					</>
				)}
				{isLoading && (
					<>
						<Skeleton
							variant={"rectangular"}
							width={"100px"}
							height={"100px"}
						/>
						<Skeleton
							variant={"rectangular"}
							width={"100px"}
							height={"100px"}
						/>
						<Skeleton
							variant={"rectangular"}
							width={"100px"}
							height={"100px"}
						/>
					</>
				)}
			</Stack>
		</>
	);
};

export default Image;
