import { useMutation, useQuery, useQueryClient } from "react-query";
import instance from "../service/instance";

const getVideoDetails = () => {
  return instance.get(`youtube-video`, {});
};

export const useGetVideoDetails = () => {
  return useQuery("get-youtube-video", () => getVideoDetails(), {});
};

const createVideo = (data) => {
  return instance.post(`youtube-video`, data);
};

export const useCreateVideo = () => {
  const queryClient = useQueryClient();
  return useMutation(createVideo, {
    onSuccess: () => queryClient.invalidateQueries("get-youtube-video"),
  });
};

const updateVideo = ({ id, data }) => {
  return instance.put(`youtube-video/${id}`, data);
};

export const useUpdateVideo = () => {
  const queryClient = useQueryClient();
  return useMutation(updateVideo, {
    onSuccess: () => queryClient.invalidateQueries("get-youtube-video"),
  });
};

const delVideo = (id) => {
  return instance.delete(`youtube-video/${id}`);
};

export const useDelVideo = () => {
  const queryClient = useQueryClient();
  return useMutation(delVideo, {
    onSuccess: () => queryClient.invalidateQueries("get-youtube-video"),
  });
};
