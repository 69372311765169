import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Typography,
  Grid,
  Switch,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FiEdit2 } from "react-icons/fi";
import { MdClose, MdDeleteForever, MdOutlineCheck } from "react-icons/md";
import DropZone from "../../components/DropZone";
import ShowError from "../../components/ShowError";
import snackContext from "../../context/snackProvider";
import {
  useGetAllCategory,
  useGetSubCategoryFromCatID,
} from "../../query/category";
import {
  useCreateProduct,
  useDelProductImage,
  useUpdateProduct,
  useUploadProductImage,
} from "../../query/product";
import { rootURL } from "../../service/instance";
import tableOptionsStyle from "../../style/tableOptions";
import { responseHandler } from "../../utilities/response-handler";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";
import { useCreateOffer } from "../../query/offer";

// Ensure you register the size module with Quill
const Size = Quill.import("attributors/style/size");
Size.whitelist = [
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
];
Quill.register(Size, true);

const OfferForm = ({ selectionModel }) => {
  const snack = React.useContext(snackContext);

  const {
    register,
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [image, setImage] = React.useState();
  const [categoryId, setCategoryId] = React.useState();
  const [discountType, setDiscountType] = React.useState();
  const [imagePosition, setImagePosition] = React.useState();
  const [multiimgs, setMultiimgs] = React.useState([]);
  const [richText, setRichText] = useState("");

  const modules = {
    toolbar: [
      [
        { font: [] },
        {
          size: Size.whitelist,
        },
      ],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ direction: "rtl" }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const {
    data: catData,
    // isLoading: isCatLoading
  } = useGetAllCategory();

  const {
    data: subCatData,
    // isLoading: isSubCatLoading,
    isError: isSubCatError,
  } = useGetSubCategoryFromCatID(categoryId);

  // const { mutateAsync: mutateCreateProduct, isLoading } = useCreateProduct();
  const { mutateAsync: mutateCreateOffer, isLoading } = useCreateOffer();

  const handleQuillChange = (value) => {
    setValue("terms_and_conditions", value);
  };

  const handleUpdate = async (data) => {
    let totalProduct = 0;

    let formData = {
      selected_products: selectionModel,
      image_position: data.image_position,
      campaign_name: data.campaign_name,
      terms_and_conditions: data.terms_and_conditions,
      start_date: data.start_date,
      end_date: data.end_date,
      discount_type: data.discount_type,
      discount: data.discount,
      amount: data.amount,
      minimum_amount: data.minimum_amount,
      minimum_product_order: data.minimum_product_order,
      // category_id: data.category_id,
      pnd_service: data.pnd_service ? 1 : 0,
      brothers_importing: data.brothers_importing ? 1 : 0,
      published: data.published ? 1 : 0,
    };
    if (image?.previous !== true) {
      formData = {
        ...formData,
        photo: image,
      };
    }
    if (data.title_ba) {
      formData = {
        ...formData,
        title_ba: data.title_ba,
      };
    }

    multiimgs?.map((multiimg, index) => {
      formData = {
        ...formData,
        [`multi_img[${index}]`]: multiimg,
      };
    });

    console.log("form data", formData);

    const res = await responseHandler(() => mutateCreateOffer(formData));
    // console.log(res);
    if (res.status) {
      snack.createSnack(res.msg);
      // reset();
    } else {
      snack.createSnack(res.msg, "error");
    }
  };

  // Function to handle form reset
  const handleCancel = () => {
    reset();
  };

  React.useEffect(() => {
    setImage();
    setCategoryId();
    setDiscountType();
    setImagePosition();
    setMultiimgs([]);
    reset();
    setRichText();
  }, []);

  return (
    <Container
      anchor="right"
      PaperProps={{
        sx: {
          mt: 5,
          width: "95vw",
          maxWidth: "570px",
        },
      }}
    >
      <form onSubmit={handleSubmit(handleUpdate)}>
        <List
          disablePadding
          sx={{
            flex: 1,
            mt: 3,
          }}
        >
          <ListItem
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              rowGap: 1,
            }}
          >
            Offer Image
            <Select
              {...register("position", {
                required: "Image position is required",
              })}
              sx={tableOptionsStyle}
              value={imagePosition || "null"}
              onChange={(e) => {
                setImagePosition(e.target.value);
                setValue("image_position", e.target.value);
              }}
              placeholder={"Select Discount Type"}
              fullWidth
            >
              <MenuItem value={"null"} disabled>
                position
              </MenuItem>
              <MenuItem value="left">Left</MenuItem>
              <MenuItem value="right">Right</MenuItem>
              <MenuItem value="middle">Middle</MenuItem>
            </Select>
            <ShowError err={errors.discountType} />
          </ListItem>

          <ListItem>
            <Box
              sx={
                {
                  // mx: "auto",
                }
              }
            >
              <DropZone
                maxSize={10242880}
                defaultValue={image}
                onChange={(data) => {
                  setImage(data);
                  return true;
                }}
                onDelete={() => {
                  setImage();
                  return true;
                }}
              />
            </Box>
          </ListItem>
          <ListItem
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              rowGap: 1,
            }}
          >
            Campaign Name
            <InputBase
              sx={tableOptionsStyle}
              placeholder={"Campaign Name"}
              {...register("campaign_name", {
                required: true,
              })}
              fullWidth
            />
            <ShowError err={errors.campaign_name} />
          </ListItem>
          <ListItem
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              rowGap: 1,
            }}
          >
            <Box
              sx={{
                mt: 1,
                height: "200px",
                width: "100%",
                "& .ql-container": {
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                },
                "& .ql-editor": {
                  flex: 1,
                  overflowY: "auto",
                },
                "& .ql-toolbar": {
                  flex: "none",
                },
              }}
            >
              <Typography variant="h6" gutterBottom>
                Terms & Condition
              </Typography>
              <ReactQuill
                theme="snow"
                onChange={handleQuillChange}
                modules={modules}
                placeholder="Write something awesome..."
                style={{ height: "100%" }}
              />
              <input
                type="hidden"
                {...register("terms_and_conditions", {
                  required: "Terms & Conditions are required",
                })}
              />
              <ShowError err={errors.terms_and_conditions} />
            </Box>
          </ListItem>

          <ListItem
            sx={{
              mt: {
                sm: 10,
                xs: 20,
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" gutterBottom>
                  Start Date
                </Typography>
                <InputBase
                  sx={tableOptionsStyle}
                  {...register("start_date", {
                    required: "Start date is required",
                  })}
                  placeholder="Start Date"
                  type="date"
                  fullWidth
                  error={!!errors.start_date}
                />
                <ShowError err={errors.start_date} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" gutterBottom>
                  End Date
                </Typography>
                <InputBase
                  sx={tableOptionsStyle}
                  {...register("end_date", {
                    required: "End date is required",
                  })}
                  placeholder="End Date"
                  type="date"
                  fullWidth
                  error={!!errors.end_date}
                />
                <ShowError err={errors.end_date} />
              </Grid>
            </Grid>
          </ListItem>

          <ListItem
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              rowGap: 1,
            }}
          >
            Discount Type
            <Select
              {...register("discountType", {
                required: "Discount type is required",
              })}
              sx={tableOptionsStyle}
              value={discountType || "null"}
              onChange={(e) => {
                setDiscountType(e.target.value);
                setValue("discount_type", e.target.value);
              }}
              placeholder={"Select Discount Type"}
              fullWidth
            >
              <MenuItem value={"null"} disabled>
                Discount Type
              </MenuItem>
              <MenuItem value="percentage">Percentage</MenuItem>
              <MenuItem value="amount">Amount</MenuItem>
            </Select>
            <ShowError err={errors.discountType} />
          </ListItem>

          <ListItem>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  Discount
                </Typography>
                <InputBase
                  sx={tableOptionsStyle}
                  {...register("discount", {
                    required: "Discount is required",
                  })}
                  placeholder="Discount"
                  type="number"
                  fullWidth
                  error={!!errors.discount}
                />
                <ShowError err={errors.discount} />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  Amount
                </Typography>
                <InputBase
                  sx={tableOptionsStyle}
                  {...register("amount", { required: "Amount is required" })}
                  placeholder="Amount"
                  type="number"
                  fullWidth
                  error={!!errors.amount}
                />
                <ShowError err={errors.amount} />
              </Grid>
            </Grid>
          </ListItem>

          <ListItem
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              rowGap: 1,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Minimum Amount
            </Typography>
            <InputBase
              sx={tableOptionsStyle}
              {...register("minimum_amount")}
              placeholder="Minimum Amount"
              type="number"
              fullWidth
            />
          </ListItem>
          <ListItem
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              rowGap: 1,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Minimum Product Order
            </Typography>
            <InputBase
              sx={tableOptionsStyle}
              {...register("minimum_product_order")}
              placeholder="Minimum Product Order"
              type="number"
              fullWidth
            />
          </ListItem>

          <ListItem
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="h6" gutterBottom>
              This Offer is available for
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  {...register("pnd_service")}
                  defaultChecked={!!getValues("pnd_service")}
                />{" "}
                PND Service
              </Box>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  {...register("brothers_importing")}
                  defaultChecked={!!getValues("brothers_importing")}
                />{" "}
                Brothers Importing
              </Box>
            </Box>
          </ListItem>

          <ListItem>
            <FormControlLabel
              control={
                <Switch
                  {...register("published")}
                  defaultChecked={!!getValues("published")}
                />
              }
              label="Published"
            />
          </ListItem>

          <ListItem
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "space-between",
              rowGap: 1,
              columnGap: 1,
            }}
          >
            <Button
              type="button"
              variant="contained"
              color="error"
              onClick={handleCancel}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type={"submit"}
              disabled={isLoading}
              fullWidth
            >
              Upload
            </Button>
          </ListItem>
        </List>
      </form>
    </Container>
  );
};

export default OfferForm;
