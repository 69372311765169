import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { IoMdEye } from "react-icons/io";
import { MdProductionQuantityLimits } from "react-icons/md";
import { TbTruckReturn } from "react-icons/tb";
import StateViewer from "../../components/StateViewer";
import DataTable from "../../components/DataTable";
import tableOptionsStyle from "../../style/tableOptions";
import snackContext from "../../context/snackProvider";
import { responseHandler } from "../../utilities/response-handler";
import biInvoiceContext from "../../context/biInvoiceProvider";
import orderContext from "../../context/orderProvider";
import CartsDialog from "../../components/CartsDialog";
import {
  useHoldBIOrder,
  useCancelBIOrder,
  useCompleteBIOrder,
  useConfirmBIOrder,
  useGetAllBIOrder,
  useUpdateBIOrder,
} from "../../query/bi-order";
import MultiInvoice from "../../context/invoiceProvider/MultiInvoice";
import BiMultiInvoice from "../../context/biInvoiceProvider/BiMultiInvoice";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import format from "date-fns/format";
/**
 * get-all-pending
 * get-all-confirm
 * get-all-deliver
 * get-all-cancel
 * get-all-order
 */
const Index = () => {
  const biInvoice = React.useContext(biInvoiceContext);
  const order = React.useContext(orderContext);
  const snack = React.useContext(snackContext);
  const [params, setParams] = React.useState({
    method: "all",
    date: "all",
    limit: 10,
    page: 1,
    filters: [],
  });

  const { data, isLoading } = useGetAllBIOrder(params);
  console.log("Bi order: ", data);
  // useMutations
  const { mutateAsync: mutateHoldBIOrder } = useHoldBIOrder();
  const { mutateAsync: mutateConfirmBIOrder } = useConfirmBIOrder();
  const { mutateAsync: mutateCompleteBIOrder } = useCompleteBIOrder();
  const { mutateAsync: mutateCancelBIOrder } = useCancelBIOrder();
  const { mutateAsync: updateBIOrder } = useUpdateBIOrder(data);

  const handleUpdate = async (data) => {
    const res = await responseHandler(
      () =>
        updateBIOrder({ orderID: data.id, data: { [data.field]: data.value } }),
      [200]
    );
    if (res.status) {
      snack.createSnack(res.data.message);
    } else {
      snack.createSnack(res.data.message, "error");
    }
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  // console.log("selected : ", selectedRows);
  const [open, setOpen] = React.useState(false);
  const [invoiceData, setInvoiceData] = React.useState([]);
  const handleUnselect = () => {
    setSelectedRows(0);
  };
  const handleClickOpen = () => {
    const rows = data?.data?.value?.data?.data;
    const invoice_data = rows.filter((row) => selectedRows.includes(row.id));
    setInvoiceData(invoice_data);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [customDateRange, setCustomDateRange] = React.useState(null);

  const handleCustomDateRangeSelect = (range) => {
    setCustomDateRange(range);
    setParams({
      ...params,

      date: `filters[]=date=custom_date_range&start_date=${format(
        range.startDate,
        "yyyy-MM-dd"
      )}&end_date=${format(range.endDate, "yyyy-MM-dd")}&`,
    });
  };

  const cols = [
    // {
    //   // headerName: "#",
    //   // field: "show_info",
    //   // width: 60,
    //   headerName: "Invoice",
    //   field: "show_info",
    //   width: 100,
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: (d) => (
    //     <>
    //       <IconButton
    //         size={"small"}
    //         onClick={() => {
    //           invoice.showInvoice(d.row.id);
    //         }}
    //       >
    //         <IoMdEye />
    //       </IconButton>
    //     </>
    //   ),
    //   sortable: false,
    // },
    {
      headerName: "Name",
      headerAlign: "center",
      field: "receiver_name",
      width: 200,
      align: "center",
      sortable: false,
      editable: true,
    },
    {
      headerName: "Phone",
      headerAlign: "center",
      field: "receiver_number",
      align: "center",
      width: 120,
      sortable: false,
      editable: true,
    },
    {
      headerName: "Address",
      headerAlign: "center",
      field: "receiver_address",
      align: "center",
      width: 250,
      sortable: false,
      editable: true,
    },
    {
      headerName: "Products",
      field: "products",
      align: "center",
      headerAlign: "center",
      renderCell: (d) => {
        return (
          <>
            <Tooltip title={"Show Products"}>
              <CartsDialogButton
                carts={d.row.carts}
                orderId={d.row.id}
                userId={d.row.user_id}
                isBi={true}
              />
            </Tooltip>
          </>
        );
      },
      sortable: false,
    },
    {
      headerName: "Date",
      field: "created_at",
      width: 170,
      headerAlign: "center",
      renderCell: (d) => {
        return <p>{moment(d.row.created_at).format("DD/MM/YYYY hh:mm a")}</p>;
      },
      sortable: false,
    },
    {
      headerName: "Status",
      field: "status",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (d) => {
        var color;
        var text;
        switch (d.row.status) {
          case "new":
            color = "info";
            text = "Pending";
            break;
          case "hold":
            color = "primary";
            text = "Hold";
            break;
          case "in progress":
            color = "warning";
            text = "Confirm";
            break;
          case "cancel":
            color = "error";
            break;
          case "delivered":
            color = "success";
            break;
          default:
            color = "default";
            break;
        }
        return (
          <Chip
            label={text || d.row.status}
            color={color}
            size={"small"}
            sx={{
              textTransform: "uppercase",
            }}
          />
        );
      },
      sortable: false,
    },
    {
      headerName: "Track Id",
      field: "tracking_code",
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (d) => {
        return (
          <>
            <IconButton
              size={"small"}
              color={"black"}
              onClick={() => {
                window.open(
                  `https://steadfast.com.bd/t/${d.row.tracking_code}`
                );
              }}
            >
              <TbTruckReturn />
            </IconButton>
          </>
        );
      },
    },
    {
      headerName: "Method",
      field: "payment_method",
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      headerName: "BI Price",
      field: "sub_total",
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      headerName: "COD Fee",
      field: "cod_charge",
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      headerName: "Delivery Fee",
      field: "shipping_total_cost",
      align: "center",
      headerAlign: "center",
      sortable: false,
      editable: true,
    },
    {
      headerName: "Total Price",
      field: "total_amount",
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      headerName: "Status Action",
      field: "status_update",
      width: 150,
      headerAlign: "center",
      renderCell: (d) => {
        return (
          <>
            <Select
              size={"small"}
              value={d.row.status}
              disabled={
                d.row.status === "delivered" || d.row.status === "cancel"
              }
              onChange={async (e) => {
                if (e.target.value === "hold") {
                  const res = await responseHandler(
                    () => mutateHoldBIOrder(d.row.id),
                    [200]
                  );
                  if (res.status) {
                    snack.createSnack(res.msg);
                  } else {
                    snack.createSnack(res.msg, "error");
                  }
                } else if (e.target.value === "in progress") {
                  const res = await responseHandler(
                    () => mutateConfirmBIOrder(d.row.id),
                    [200]
                  );
                  if (res.status) {
                    snack.createSnack(res.msg);
                  } else {
                    snack.createSnack(res.msg, "error");
                  }
                } else if (e.target.value === "delivered") {
                  const res = await responseHandler(
                    () => mutateCompleteBIOrder(d.row.id),
                    [200]
                  );
                  if (res.status) {
                    snack.createSnack(res.msg);
                  } else {
                    snack.createSnack(res.msg, "error");
                  }
                } else if (e.target.value === "cancel") {
                  const res = await responseHandler(
                    () => mutateCancelBIOrder(d.row.id),
                    [200]
                  );
                  if (res.status) {
                    snack.createSnack(res.msg);
                  } else {
                    snack.createSnack(res.msg, "error");
                  }
                }
              }}
              fullWidth
            >
              <MenuItem value={"new"} disabled>
                Pending
              </MenuItem>
              <MenuItem value={"hold"} disabled={d.row.status === "hold"}>
                Hold
              </MenuItem>
              <MenuItem
                value={"in progress"}
                disabled={d.row.status === "in progress"}
              >
                Confirm
              </MenuItem>
              <MenuItem value={"in_review"} disabled>
                In Review
              </MenuItem>
              <MenuItem value={"partial_delivered"} disabled>
                Partial Delivered
              </MenuItem>
              <MenuItem
                value={"delivered"}
                disabled={d.row.status === "delivered"}
              >
                Delivered
              </MenuItem>
              <MenuItem value={"cancel"} disabled={d.row.status === "cancel"}>
                Cancel
              </MenuItem>
            </Select>
          </>
        );
      },
      sortable: false,
    },
    {
      headerName: "Invoice",
      field: "invoice_print",
      align: "center",
      headerAlign: "center",
      renderCell: (d) => {
        return (
          <>
            <Tooltip title={"Show Invoice"}>
              <IconButton
                size={"small"}
                color={"black"}
                onClick={() => {
                  // console.log(d.row);
                  // window.open(
                  //   "https://admin.pndservicebd.com/orderdetails.html?id=" +
                  //     d.row.id
                  // );
                  biInvoice.showBiInvoice(d.row.id);
                }}
              >
                <IoMdEye />
              </IconButton>
            </Tooltip>
          </>
        );
      },
      sortable: false,
    },
    {
      headerName: "Action",
      field: "return_button",
      align: "center",
      headerAlign: "center",
      renderCell: (d) => {
        return (
          <>
            <Tooltip title={"Return Order"}>
              <span>
                <IconButton
                  size={"small"}
                  color={"black"}
                  onClick={() => {
                    order.showOrder(d.row.id);
                  }}
                  disabled={
                    d.row.status !== "in progress" &&
                    d.row.status !== "delivered"
                  }
                >
                  <TbTruckReturn />
                </IconButton>
              </span>
            </Tooltip>
          </>
        );
      },
      sortable: false,
    },
  ];

  return (
    <Container
      sx={{
        py: 2,
      }}
    >
      <StateViewer
        stateList={[
          {
            num: data?.data?.value?.total_delivered_order,
            title: "Total Delivered",
            price: data?.data?.value?.total_delivered_order_amount,
            title2: "Amount",
          },
          {
            num: data?.data?.value?.total_confirm_order,
            title: "Confirmed",
            price: data?.data?.value?.total_confirm_order_amount,
            title2: "Amount",
          },
          {
            num: data?.data?.value?.total_pending_order,
            title: "Pending",
            price: data?.data?.value?.total_pending_order_amount,
            title2: "Amount",
          },

          {
            num: data?.data?.value?.total_cancel_order,
            title: "Canceled",
            price: data?.data?.value?.total_cancel_order_amount,
            title2: "Amount",
          },
        ]}
      />

      <Paper
        elevation={0}
        sx={{
          p: 2,
          border: "1px solid #ccc",
          my: 2,
        }}
      >
        <Grid
          container
          rowGap={1}
          columnGap={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item xs={12} sm={3.5}>
            <InputBase
              placeholder="Search Order"
              sx={tableOptionsStyle}
              onChange={(e) => {
                setParams({
                  ...params,
                  filters: [
                    // `receiver_name~${e.target.value}`,
                    `receiver_number~${e.target.value}`,
                    // `receiver_address~${e.target.value}`,
                  ],
                });
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={2.5}>
            <Select
              sx={{
                ...tableOptionsStyle,
              }}
              value={params.method}
              onChange={(e) =>
                setParams({
                  ...params,
                  method: e.target.value,
                })
              }
              // disabled={params.method === "delivered"}
              fullWidth
            >
              <MenuItem value={"all"} disabled={params.method === "all"}>
                All
              </MenuItem>
              <MenuItem
                value={"filters[]=status=new&"}
                disabled={params.method === "filters[]=status=new&"} // new
              >
                Pending
              </MenuItem>
              <MenuItem
                value={"filters[]=status=hold&"}
                disabled={params.method === "filters[]=status=hold&"} // new
              >
                Hold
              </MenuItem>
              <MenuItem
                value={"filters[]=status=in progress&"}
                disabled={params.method === "filters[]=status=in progress&"} // in progress
              >
                Confirmed
              </MenuItem>
              <MenuItem
                value={"filters[]=status=delivered&"}
                disabled={params.method === "filters[]=status=delivered&"} // delivered
              >
                Delivered
              </MenuItem>
              <MenuItem
                value={"filters[]=status=cancel&"}
                disabled={params.method === "filters[]=status=cancel&"} // cancel
              >
                Canceled
              </MenuItem>
            </Select>
          </Grid>
          {/* search by date */}
          <Grid item xs={12} sm={5}>
            <Select
              sx={{
                ...tableOptionsStyle,
              }}
              value={params.date}
              onChange={(e) =>
                setParams({
                  ...params,
                  date: e.target.value,
                })
              }
              fullWidth
            >
              <MenuItem value={"all"} disabled={params.date === "all"}>
                order by date
              </MenuItem>
              <MenuItem value={"filters[]=date=today&"}>Today</MenuItem>
              <MenuItem value={"filters[]=date=yesterday&"}>
                Yesterday
              </MenuItem>
              <MenuItem value={"filters[]=date=last_7_days&"}>
                Last 7 days
              </MenuItem>
              <MenuItem value={"filters[]=date=last_30_days&"}>
                Last 30 days
              </MenuItem>
              <MenuItem value={"filters[]=date=this_year&"}>
                This Year
              </MenuItem>
              <MenuItem value={"filters[]=date=life_time&"}>
                Life Time
              </MenuItem>
              <MenuItem value={"custom_date_range"}>Custom</MenuItem>
            </Select>
            {params.date === "custom_date_range" && (
              <CustomDateRangePicker
                onDateRangeSelect={handleCustomDateRangeSelect}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            {selectedRows.length > 0 && (
              <>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleClickOpen}
                  >
                    Print Invoice
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleUnselect}
                  >
                    Cancel
                  </Button>
                </Box>
                <BiMultiInvoice
                  open={open}
                  onClose={handleClose}
                  data={invoiceData}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
      <DataTable
        columns={cols}
        rows={data?.data?.value?.data?.data || []}
        onCellEditCommit={handleUpdate}
        experimentalFeatures={{ newEditngApi: true }}
        isLoading={isLoading}
        paginationMode={"server"}
        rowCount={data?.data?.value?.data?.total || 0}
        onSelectionModelChange={(newSelection) => {
          setSelectedRows(newSelection);
        }}
        selectionModel={selectedRows}
        page={(params?.page || 1) - 1}
        onPageChange={(newPage) =>
          setParams({
            ...params,
            page: newPage + 1,
          })
        }
        pageSize={params?.limit}
        onPageSizeChange={(pageSize) =>
          setParams({
            ...params,
            limit: pageSize,
            page: 1,
          })
        }
      />
    </Container>
  );
};

export const CartsDialogButton = ({ carts, orderId, userId }) => {
  const [open, setOpen] = React.useState(false);
  const onClose = () => setOpen(!open);
  return (
    <>
      <IconButton
        size={"small"}
        color={"black"}
        disabled={!carts?.length}
        onClick={onClose}
      >
        <MdProductionQuantityLimits />
      </IconButton>
      {open && (
        <CartsDialog
          open={open}
          onClose={onClose}
          carts={carts || []}
          orderId={orderId}
          userId={userId}
          isBi={true}
        />
      )}
    </>
  );
};

export default Index;
